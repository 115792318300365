export const taskList = [
    {
        name: "task1",
        description: "Task1 description",
        isDone: false,
        id: 1
    },
    {
        name: "task2",
        description: "Task2 description",
        isDone: false,
        id: 2
    },
    {
        name: "task3",
        description: "Task3 description",
        isDone: false,
        id: 3
    },
]